// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'
import moment from 'moment'

export const getSchoolAdminSchedule = createAsyncThunk('schoolAdmin/schedule', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminSchedule(
    getState().schoolAdminSchedule.params
  )

  return response.data
})

export const schoolAdminScheduleSlice = createSlice({
  name: 'schoolAdminSchedule',
  initialState: {
    isLoading: false,
    data: [],
    total: 0,
    params: {
      startDate: moment().startOf('isoWeek').toISOString(),
      endDate: moment().endOf('isoWeek').toISOString()
    },
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSchoolAdminSchedule.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getSchoolAdminSchedule.fulfilled, (state, action) => {
      state.data = action.payload.data.dates
      state.isLoading = false
    })
    
  }
})

export const { changeParams } = schoolAdminScheduleSlice.actions

export default schoolAdminScheduleSlice.reducer