// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getStudentScheduleDetail = createAsyncThunk('student/schedule/detail', async (payload, { dispatch, getState }) => {
  const response = await useApi.studentScheduleGetDetail({ lessonId: payload})
  return response.data
})

export const studentScheduleDetailModalSlice = createSlice({
  name: 'studentScheduleDetailModal',
  initialState: {
    isModalOpened: false,
    isLoading: false,
    data: null,
    params: {
      lessonId: null
    },
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
    closeModal(state, action) {
      state.params = { lessonId: null }
      state.isModalOpened = false
      state.isLoading = false
      state.data = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStudentScheduleDetail.pending, (state) => {
      state.isLoading = true
      state.isModalOpened = true
    })
    builder.addCase(getStudentScheduleDetail.fulfilled, (state, action) => {
      state.data = action.payload.data.lesson
      // state.total = action.payload._meta.pagination.total
      state.isLoading = false
    })
    
  }
})

export const { changeParams, closeModal } = studentScheduleDetailModalSlice.actions

export default studentScheduleDetailModalSlice.reducer