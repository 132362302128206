// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'
import toast from 'react-hot-toast'

import { handleSetData } from '../../../redux/auth'

export const getStudentPlans = createAsyncThunk('student/plans/list', async (payload, { dispatch, getState }) => {
  const response = await useApi.studentPlansList(getState().studentPlans.params)
  return response.data
})

export const payStudentPlan = createAsyncThunk('student/plans/pay', async (payload, { dispatch, getState, rejectWithValue }) => {
  try {
    const response = await useApi.studentPlansPay(payload)

    if (response.data.success) {
      await dispatch(getStudentPlans())
    
      useApi.authGetData().then((res) => {
        if (res.data.success && res.data.data) {
          dispatch(handleSetData({
            school: res.data.data.school || null,
            user: res.data.data.user
          }))
        }
      })

      return response.data
    } else {
      if (response.data.notEnoughMoney) {
        dispatch(setAmountToPay(parseFloat(response.data.notEnoughMoney) / 100))
        dispatch(togglePaymentModal(true))
        dispatch(setSelectedPlanToPay(response.data.packId))
      }

      return response.data
    }
  } catch (e) {
    return rejectWithValue(e.response)
  }
})

export const studentPlansSlice = createSlice({
  name: 'studentPlans',
  initialState: {
    isLoading: false,
    data: [],
    otherPlans: [],
    total: 0,
    params: {},
    isPaymentModalOpened: false,
    selectedPlanToPay: null,
    amountToPay: null,
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
    togglePaymentModal(state, action) {
      state.isPaymentModalOpened = action.payload
    },
    setSelectedPlanToPay(state, action) {
      state.selectedPlanToPay = action.payload
    },
    setAmountToPay(state, action) {
      state.amountToPay = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getStudentPlans.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getStudentPlans.fulfilled, (state, action) => {
      state.data = action.payload.data.plans
      state.otherPlans = action.payload.data.otherPlans || []
      state.isLoading = false
    })

    builder.addCase(payStudentPlan.pending, (state) => {
      state.isLoading = true
    })

    builder.addCase(payStudentPlan.fulfilled, (state) => {
      state.isLoading = false
    })

    builder.addCase(payStudentPlan.rejected, (state, action) => {
      state.isLoading = false

      toast.error(action.payload.data.message)
      //console.log(action)
    })
  }
})

export const { changeParams, togglePaymentModal, setSelectedPlanToPay, setAmountToPay } = studentPlansSlice.actions

export default studentPlansSlice.reducer