// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

// export const getTeacherScheduleDetail = createAsyncThunk('teacher/schedule/lessons/students-groups', async (payload, { dispatch, getState }) => {
//   const response = await useApi.teacherScheduleGetDetail(getState().teacherScheduleDetailModal.params)
//   return response.data
// })

export const teacherScheduleCreateLessonModalSlice = createSlice({
  name: 'teacherScheduleCreateLessonModal',
  initialState: {
    isModalOpened: false,
    isLoading: false,
    data: null,
    selectedDate: null,
    params: {
     
    },
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
    closeModal(state, action) {
      state.params = { lessonId: null }
      state.isModalOpened = false
      state.isLoading = false
      state.data = null
    },
    openModal(state, action) {
      state.selectedDate = action.payload
      state.isModalOpened = true
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(getTeacherScheduleDetail.pending, (state) => {
    //   state.isLoading = true
    //   state.isModalOpened = true
    // })
    // builder.addCase(getTeacherScheduleDetail.fulfilled, (state, action) => {
    //   state.data = action.payload.data.lesson
    //   // state.total = action.payload._meta.pagination.total
    //   state.isLoading = false
    // })
    
  }
})

export const { changeParams, closeModal, openModal } = teacherScheduleCreateLessonModalSlice.actions

export default teacherScheduleCreateLessonModalSlice.reducer