// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getLessonsGroups = createAsyncThunk('schoolAdmin/lessons/groups/list', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminLessonsGroupsList(getState().schoolAdminLessonsGroups.params)
  return response.data
})

export const schoolAdminLessonsGroupsSlice = createSlice({
  name: 'schoolAdminLessonsGroups',
  initialState: {
    isLoading: false,
    data: [],
    total: 0,
    params: {
      itemsPerPage: 10,
      currentPage: 1,
      q: ''
    },
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLessonsGroups.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getLessonsGroups.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload._meta.pagination.total
      state.isLoading = false
    })
    
  }
})

export const { changeParams } = schoolAdminLessonsGroupsSlice.actions

export default schoolAdminLessonsGroupsSlice.reducer