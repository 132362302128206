// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getSchoolSettings = createAsyncThunk('schoolAdmin/schoolSettings/getSettings', async id => {
  const response = await useApi.schoolAdminGetSchoolSettings()
  return response.data.data
})

export const updateSchoolSettings = createAsyncThunk('schoolAdmin/schoolSettings/updateSettings', async (payload, { dispatch }) => {
  const response = await useApi.schoolAdminUpdateSchoolSettings(payload)
  
  await dispatch(getSchoolSettings())

  return response.data.data
})

export const uploadLogo = createAsyncThunk('schoolAdmin/schoolSettings/uploadLogo', async (payload, { dispatch }) => {
  const response = await useApi.schoolAdminUploadSchoolLogo(payload)
  await dispatch(getSchoolSettings())

  return response.data.data
})

export const addLessonDuration = createAsyncThunk('schoolAdmin/schoolSettings/lessonsDuration/add', async (payload, { dispatch }) => {
  const response = await useApi.schoolAdminAddLessonDuration(payload)
  await dispatch(getSchoolSettings())

  return response.data.data
})

export const removeLessonDuration = createAsyncThunk('schoolAdmin/schoolSettings/lessonsDuration/remove', async (payload, { dispatch }) => {
  const response = await useApi.schoolAdminRemoveLessonDuration(payload)
  await dispatch(getSchoolSettings())

  return response.data.data
})

export const updateWorkingSchedule = createAsyncThunk('schoolAdmin/schoolSettings/workingSchedule/update', async (payload, { dispatch }) => {
  const response = await useApi.schoolAdminUpdateWorkingSchedule(payload)
  await dispatch(getSchoolSettings())

  return response.data.data
})

export const updatePaymentSettings = createAsyncThunk('schoolAdmin/schoolSettings/paymentSettings/update', async (payload, { dispatch }) => {
  const response = await useApi.schoolAdminUpdatePaymentSchoolSettings(payload)
  await dispatch(getSchoolSettings())

  return response.data.data
})

export const appUsersSlice = createSlice({
  name: 'schoolAdminSchoolSettings',
  initialState: {
    settings: null,
    isSettingsLoading: true,
    isSettingsUpdating: false,
    isLessonsDurationAdding: false,
    isPaymentSettingsUpdating: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSchoolSettings.pending, (state) => {
      state.isSettingsLoading = true
    })
    builder.addCase(getSchoolSettings.fulfilled, (state, action) => {
      state.settings = action.payload
      state.isSettingsLoading = false
    })
    builder.addCase(updateSchoolSettings.pending, (state) => {
      state.isSettingsUpdating = true
    })
    builder.addCase(updateSchoolSettings.fulfilled, (state) => {
      state.isSettingsUpdating = false
    })
    builder.addCase(uploadLogo.pending, (state) => {
      state.isSettingsUpdating = true
    })
    builder.addCase(updatePaymentSettings.pending, (state) => {
      state.isPaymentSettingsUpdating = true
    })
    builder.addCase(uploadLogo.fulfilled, (state) => {
      state.isSettingsUpdating = false
    })
    builder.addCase(addLessonDuration.pending, (state) => {
      state.isLessonsDurationAdding = true
    })
    builder.addCase(addLessonDuration.fulfilled, (state) => {
      state.isLessonsDurationAdding = false
    })
    builder.addCase(updatePaymentSettings.fulfilled, (state) => {
      state.isPaymentSettingsUpdating = false
    })
  }
})

export default appUsersSlice.reducer
