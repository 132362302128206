// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'
import moment from 'moment'

export const getSchoolAdminScheduleDetail = createAsyncThunk('schoolAdmin/schedule/detail', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminScheduleDetail(
    payload
  )

  return response.data
})

export const schoolAdminScheduleDetailSlice = createSlice({
  name: 'schoolAdminScheduleDetail',
  initialState: {
    isLoading: false,
    isModalOpened: false,
    data: [],
    params: {
      
    },
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
    openModal(state, action) {
      state.isModalOpened = true
    },
    closeModal(state, action) {
      state.isModalOpened = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSchoolAdminScheduleDetail.pending, (state) => {
      state.isLoading = true
      state.isModalOpened = true
    })
    builder.addCase(getSchoolAdminScheduleDetail.fulfilled, (state, action) => {
      state.data = action.payload.data.lessons
      state.isLoading = false
    })
    
  }
})

export const { changeParams, closeModal } = schoolAdminScheduleDetailSlice.actions

export default schoolAdminScheduleDetailSlice.reducer