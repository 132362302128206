// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

import toast from 'react-hot-toast'

export const getClassHomework = createAsyncThunk('student/classes/homework/get', async (payload, { dispatch, getState }) => {
  const response = await useApi.studentClassHomeworkGet(payload.classId, payload.homeworkId, {})
  return response.data
})

export const addAnswerApi = createAsyncThunk('student/classes/addAnswer', async (payload, { dispatch, getState }) => {
  const response = await useApi.studentClassAddAnswer(payload.classId, payload.exerciseId, payload.isMultipleAnswers, payload.answer)
  return response.data
})

export const sendToCheck = createAsyncThunk('student/classes/homework/sendToCheck', async (payload, { dispatch, getState }) => {
  const response = await useApi.studentClassHomeworkSendToCheck(
    payload.classId, payload.homeworkId
  )
  return response.data
})

export const getVocabulary = createAsyncThunk('student/classes/vocabulary', async (payload, { dispatch, getState }) => {
  const response = await useApi.studentVocabularyList(getState().studentClass.vocabulary.params)
  return response
})

export const studentClassHomeworkSlice = createSlice({
  name: 'studentClassHomework',
  initialState: {
    selectedSection: null,
    isLoading: false,
    class: null,
    myAnswers: {},
    error: null,
    total: 0,
    params: {},
    lesesonSettings: [],
    vocabulary: {
      params: {
        itemsPerPage: 10,
        currentPage: 1,
        q: '',
        isLoadMore: false
      },
      items: [],
      isLoading: false,
      isMorePages: false
    }
  },
  reducers: {
    changeVocabulary(state, action) {
      state.vocabulary = {
        ...state.vocabulary,
        ...action.payload
      }
    },
    addAnswer(state, action) {
      if (!action.payload.isMultipleAnswers) {
        state.myAnswers[action.payload.exerciseId] = action.payload.answer
      } else {
        if (state.myAnswers[action.payload.exerciseId]) {
          state.myAnswers[action.payload.exerciseId].push(action.payload.answer)
        } else {
          state.myAnswers[action.payload.exerciseId] = [action.payload.answer]
        }
      }
    },
    clearExerciseAnswersState(state, action) {
      state.myAnswers[action.payload.exerciseId] = undefined
    },
    changeExerciseSetting(state, action) {
      const isExerciseExist = state.lesesonSettings.find(
        ls => parseInt(ls.classId) === parseInt(action.payload.classId) &&
          parseInt(ls.lessonId) === parseInt(action.payload.lessonId) &&
          parseInt(ls.exerciseId) === parseInt(action.payload.exerciseId) &&
          ls.key === action.payload.params.key
      )

      if (isExerciseExist) {
        state.lesesonSettings = state.lesesonSettings.map((ls) => {
          if (
            parseInt(ls.classId) === parseInt(action.payload.classId) &&
            parseInt(ls.lessonId) === parseInt(action.payload.lessonId) &&
            parseInt(ls.exerciseId) === parseInt(action.payload.exerciseId) &&
            ls.key === action.payload.params.key
          ) {
            ls.value = parseInt(action.payload.params.value)
          }

          return ls
        })
      } else {
        state.lesesonSettings = [
          ...state.lesesonSettings,
          {
            classId: action.payload.classId,
            lessonId: action.payload.lessonId,
            exerciseId: action.payload.exerciseId,
            key: action.payload.params.key,
            value: action.payload.params.value
          }
        ]
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClassHomework.pending, (state) => {
      state.isLoading = true
      state.error = null
    })
    builder.addCase(getClassHomework.fulfilled, (state, action) => {
      state.class = action.payload.data.class
      state.myAnswers = action.payload.data.answers
      state.class.homework = action.payload.data.homework
      state.lesesonSettings = action.payload.data.lesesonSettings
      state.error = !action.payload.data.canAccessToClass ? 'На жаль доступ до цього класу обмежений' : null
      
      state.isLoading = false
    })
    builder.addCase(getClassHomework.rejected, (state, action) => {
      state.error = 'На жаль доступ до цього класу обмежений'
      state.isLoading = false
    })
    builder.addCase(getVocabulary.pending, (state) => {
      state.vocabulary.isLoading = true
    })
    builder.addCase(getVocabulary.fulfilled, (state, action) => {
      if (state.vocabulary.params.isLoadMore) {
        state.vocabulary.items = state.vocabulary.items.concat(action.payload.data.data)
        state.vocabulary.params.isLoadMore = false
      } else {
        state.vocabulary.items = action.payload.data.data
      }

      state.vocabulary.isLoading = false
      state.vocabulary.isMorePages = parseInt(action.payload.data._meta.pagination.total) > parseInt(state.vocabulary.params.itemsPerPage) * parseInt(state.vocabulary.params.currentPage)
    })
    builder.addCase(sendToCheck.pending, (state) => {
      state.isLoading = true
      state.error = null
    })
    builder.addCase(sendToCheck.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = null

      toast.success('Успішно відправлено на перевірку', {
        duration: 5000
      })
    })
    builder.addCase(sendToCheck.rejected, (state, action) => {
      state.isLoading = false
      state.error = null
      toast.error('Упс! Щось пішло не так', {
        duration: 5000
      })
    })
  }
})

export const { addAnswer, changeExerciseSetting, changeVocabulary, clearExerciseAnswersState } = studentClassHomeworkSlice.actions

export default studentClassHomeworkSlice.reducer