// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getData = createAsyncThunk('teacher/courses/getData', async params => {
  const response = await useApi.teacherGetCourses(params)
  return {
    params,
    data: response.data.data,
    total: response.data['_meta']['pagination']['total'] || 0
  }
})

export const appUsersSlice = createSlice({
  name: 'teacherCourses',
  initialState: {
    data: [],
    total: 0,
    params: {},
    selectedCourse: null
  },
  reducers: {},
  extraReducers: {
    [getData.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.total
    }
  }
})

export default appUsersSlice.reducer
