// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getPlatformPlans = createAsyncThunk('admin/adminPlansPlatform/list', async (payload, { dispatch, getState }) => {
  const response = await useApi.adminGetPlatformPlans(getState().adminPlansPlatform.params)
  return response.data
})

export const archivePlatformPlan = createAsyncThunk('admin/adminPlansPlatform/list', async (payload, { dispatch, getState }) => {
  const response = await useApi.adminDeletePlatformPlan(payload)

  if (response.data.success) {
    await dispatch(getPlatformPlans())
  }
  
  return response.data
})

export const adminPlansPlatformSlice = createSlice({
  name: 'adminPlansPlatform',
  initialState: {
    isLoading: false,
    data: [],
    total: 0,
    params: {
      itemsPerPage: 10,
      currentPage: 1,
      q: '',
      type: 'platform'
    },
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPlatformPlans.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getPlatformPlans.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload._meta.pagination.total
      state.isLoading = false
    })
  }
})

export const { changeParams } = adminPlansPlatformSlice.actions

export default adminPlansPlatformSlice.reducer