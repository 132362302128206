// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

// ** Axios Imports

export const getData = createAsyncThunk('student/notifications/getData', async params => {
  const response = await useApi.studentNotificationsGet(params)

  return {
    params,
    data: response.data.data,
    totalPages: response.data['_meta']['pagination']['total'] || 0
  }
})

export const appUsersSlice = createSlice({
  name: 'studentNotifications',
  initialState: {
    data: [],
    total: 0,
    params: {},
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default appUsersSlice.reducer
