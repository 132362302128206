// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'
import toast from 'react-hot-toast'

export const getHandbookList = createAsyncThunk('admin/handbook/list', async (payload, { dispatch, getState }) => {
  const response = await useApi.adminHandbookList(payload)
  return response.data
})

export const createHandbook = createAsyncThunk('admin/handbook/create', async (payload, { dispatch, getState }) => {
  const response = await useApi.adminHandbookCreate(payload)

  return response.data
})

export const changePosition = createAsyncThunk('admin/handbook/changePosition', async (data, { dispatch, getState }) => {
  await useApi.adminHandbookChangePositionCourse(data)
  
  await dispatch(getHandbookList())
})

export const adminHandbookSlice = createSlice({
  name: 'adminHandbookList',
  initialState: {
    isLoading: false,
    isCreating: false,
    data: [],
    total: 0,
    params: {
      itemsPerPage: 100000,
      currentPage: 1,
      q: ''
    },
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHandbookList.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getHandbookList.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload._meta.pagination.total
      state.isLoading = false
    })

    builder.addCase(createHandbook.pending, (state) => {
      state.isCreating = true
    })
    builder.addCase(createHandbook.rejected, (state, action) => {
      toast.error('Сталась помилка. Спробуйте ще раз.', {
        duration: 5000
      })

      state.isCreating = false
    })
    builder.addCase(createHandbook.fulfilled, (state, action) => {
      state.isCreating = false

      if (action.payload.success) {
        setTimeout(() => {
          window.location.href = `/handbook/edit/${action.payload.data.id}`
        }, 50)
      }
    })
    
  }
})

export const { changeParams } = adminHandbookSlice.actions

export default adminHandbookSlice.reducer