// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'
import toast from 'react-hot-toast'

export const getMarathonsTeachers = createAsyncThunk('schoolAdmin/marathons/teachers/list', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminMarathonsTeachers(payload, getState().schoolAdminMarathonsViewTeachers.params)
  return response.data
})

export const deleteMarathonsTeacher = createAsyncThunk('schoolAdmin/marathons/teachers/delete', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminMarathonsTeachersDelete(
    payload.marathonId, payload.memberId, {}
  )

  await dispatch(getMarathonsTeachers(payload.marathonId))
  return response.data
})


export const schoolAdminMarathonsViewTeachersSlice = createSlice({
  name: 'schoolAdminMarathonsViewTeachers',
  initialState: {
    isLoading: false,
    data: [],
    params: {
      itemsPerPage: 10,
      currentPage: 1,
      q: ''
    }
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMarathonsTeachers.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getMarathonsTeachers.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload._meta.pagination.total
      state.isLoading = false
    })
    builder.addCase(deleteMarathonsTeacher.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(deleteMarathonsTeacher.fulfilled, (state) => {
      state.isLoading = false
    })
  }
})

export const { changeParams } = schoolAdminMarathonsViewTeachersSlice.actions

export default schoolAdminMarathonsViewTeachersSlice.reducer