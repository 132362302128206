// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'
import toast from 'react-hot-toast'

export const getMarathons = createAsyncThunk('schoolAdmin/marathons/get', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminMarathonsGet(payload)
  return response.data
})

export const updateMarathons = createAsyncThunk('schoolAdmin/marathons/edit', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminMarathonsEdit(payload.marathonId, payload)

  if (payload.callback && typeof payload.callback === 'function') {
    payload.callback()
  }
  
  await dispatch(getMarathons(payload.marathonId))

  return response.data
})

export const payMarathons = createAsyncThunk('schoolAdmin/marathons/pay', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminMarathonsPay(payload.marathonId)
  
  await dispatch(getMarathons(payload.marathonId))

  return response.data
})


export const schoolAdminMarathonsSlice = createSlice({
  name: 'schoolAdminMarathonsView',
  initialState: {
    isLoading: false,
    isUpdating: false,
    data: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMarathons.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getMarathons.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.isLoading = false
    })
    builder.addCase(updateMarathons.pending, (state) => {
      state.isUpdating = true
    })
    builder.addCase(updateMarathons.fulfilled, (state, action) => {
      state.isUpdating = false
    })
    builder.addCase(updateMarathons.rejected, (state, action) => {
      state.isUpdating = false
    })
  }
})

export default schoolAdminMarathonsSlice.reducer