// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import toast from 'react-hot-toast'

import useApi from '@src/api'

export const getCourseLesson = createAsyncThunk('schoolAdmin/courses/getLesson', async payload => {
  console.log(payload)
  const response = await useApi.schoolAdminGetCourseLesson(payload.courseId, payload.lessonId)
  return response.data.data
})

export const deleteLessonSection = createAsyncThunk('schoolAdmin/courses/lessons/deleteSection', async (payload, { dispatch }) => {
  const response = await useApi.schoolAdminDeleteLessonSection(payload.courseId, payload.lessonId, payload.sectionId)

  await dispatch(getCourseLesson({
    courseId: payload.courseId,
    lessonId: payload.lessonId
  }))

  return response.data.data
})

export const createLessonExercise = createAsyncThunk('schoolAdmin/courses/lessons/createExercise', async (payload, { dispatch }) => {
  const response = await useApi.schoolAdminCreateLessonExercise(payload.courseId, payload.lessonId, payload.sectionId, payload.params)

  // Reset form
  payload.reset()

  // Close modal
  dispatch(closeCreateModal())

  await dispatch(getCourseLesson({
    courseId: payload.courseId,
    lessonId: payload.lessonId
  }))

  return response.data.data
})

export const editLessonExercise = createAsyncThunk('schoolAdmin/courses/lessons/editExercise', async (payload, { dispatch }) => {
  const response = await useApi.schoolAdminEditLessonExercise(payload.courseId, payload.lessonId, payload.sectionId, payload.exerciseId, payload.params)

  payload.reset()

  await dispatch(getCourseLesson({
    courseId: payload.courseId,
    lessonId: payload.lessonId
  }))

  return response.data.data
})

export const deleteLessonExercise = createAsyncThunk('schoolAdmin/courses/lessons/deleteExercise', async (payload, { dispatch }) => {
  const response = await useApi.schoolAdminDeleteLessonExercise(
    payload.courseId,
    payload.lessonId,
    payload.sectionId,
    payload.exerciseId
  )

  await dispatch(getCourseLesson({
    courseId: payload.courseId,
    lessonId: payload.lessonId
  }))

  return response.data.data
})

export const setActiveCourseLessonSection = createAsyncThunk('schoolAdmin/courses/setActiveSection', async sectionId => {
  return sectionId
})

export const adminSchoolCourseLessonSlice = createSlice({
  name: 'schoolAdminCoursesLessonDetail',
  initialState: {
    isLoading: true,
    selectedLesson: null,
    selectedSection: null,
    editableExercise: null,
    isCreateModalOpened: false,
    isCreating: false,
    isEditing: false
  },
  reducers: {
    editExercise(state, action) {
      state.editableExercise = action.payload
    },
    openCreateModal(state, action) {
      state.isCreateModalOpened = true
    },
    closeCreateModal(state, action) {
      state.isCreateModalOpened = false
    },
    setCreating(state, action) {
      state.isCreating = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getCourseLesson.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getCourseLesson.fulfilled, (state, action) => {
        state.isLoading = false
        state.selectedLesson = action.payload

        console.log(action.payload)
        state.selectedLesson.lesson.sections = state.selectedLesson.lesson.sections.sort((a, b) => parseInt(a.position) - parseInt(b.position))
        
        if (!state.selectedSection) {
          state.selectedSection = action.payload.lesson.sections.length > 0 ? action.payload.lesson.sections[0].id : null
        } else {
          const isSectionExist = action.payload.lesson.sections.length > 0 && action.payload.lesson.sections.find(s => s.id === state.selectedSection) !== undefined

          if (!isSectionExist) {
            state.selectedSection = action.payload.lesson.sections.length > 0 ? action.payload.lesson.sections[0].id : null
          }
        }
      })
      .addCase(setActiveCourseLessonSection.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(setActiveCourseLessonSection.fulfilled, (state, action) => {
        state.isLoading = false
        state.selectedSection = action.payload
      })

      .addCase(createLessonExercise.pending, (state, action) => {
        state.isCreating = true
      })
      .addCase(createLessonExercise.fulfilled, (state, action) => {
        state.isCreating = false
      })
      .addCase(createLessonExercise.rejected, (state, action) => {
        state.isCreating = false

        toast.error('Одне або декілька полів заповнено не вірно', {
          duration: 5000
        })
      })

      .addCase(editLessonExercise.pending, (state, action) => {
        state.isEditing = true
      })
      .addCase(editLessonExercise.fulfilled, (state, action) => {
        state.isEditing = false
        state.editableExercise = null
      })
      .addCase(editLessonExercise.rejected, (state, action) => {
        state.isEditing = false


        toast.error('Одне або декілька полів заповнено не вірно', {
          duration: 5000
        })
      })
  }
})

export const { editExercise, openCreateModal, closeCreateModal} = adminSchoolCourseLessonSlice.actions

export default adminSchoolCourseLessonSlice.reducer