// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getAnalyticsAverageCheck = createAsyncThunk('schoolAdmin/analytics/averageCheck', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminAnalytics(getState().schoolAdminAnalyticsAverageCheck.params)
  return response.data
})

export const schoolAdminAnalyticsAverageCheckSlice = createSlice({
  name: 'schoolAdminAnalyticsAverageCheck',
  initialState: {
    isLoading: false,
    data: null,
    params: {
      period: 'this-week', // [this-week, previous-week, this-month, previous-month, 6-months, 1 - year],
      type: 'average-check'
    },
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAnalyticsAverageCheck.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getAnalyticsAverageCheck.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.isLoading = false
    })
    
  }
})

export const { changeParams } = schoolAdminAnalyticsAverageCheckSlice.actions

export default schoolAdminAnalyticsAverageCheckSlice.reducer