import { io } from 'socket.io-client';

class WS {
  connection = null

  static connect() {
    const token = localStorage.getItem('accessToken')
    const wsHost = process.env.REACT_APP_WSHOST

    if (token) {
      const userData = JSON.parse(localStorage.getItem('userData'))
      this.connection = io(`${wsHost}?token=${token.trim()}&userId=${userData.id}`, {
        auth: {
          userId: userData.id,
          token
        }
      })
    }
  }

  static disconnect() {
    if (this.connection) {
      this.connection.disconnect()
    }
  }

  static getConnection() {
    return this.connection
  }
}

export default WS
