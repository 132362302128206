// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getLessonsStudents = createAsyncThunk('schoolAdmin/lessons/students/list', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminLessonsStudentsList({
    ...getState().schoolAdminLessonsStudents.params
  })

  return response.data
})

export const schoolAdminLessonsStudentsSlice = createSlice({
  name: 'schoolAdminLessonsStudents',
  initialState: {
    isLoading: false,
    data: [],
    total: 0,
    params: {
      itemsPerPage: 10,
      currentPage: 1,
      q: '',
      status: 'all'
    },
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLessonsStudents.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getLessonsStudents.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload._meta.pagination.total
      state.isLoading = false
    })
    
  }
})

export const { changeParams } = schoolAdminLessonsStudentsSlice.actions

export default schoolAdminLessonsStudentsSlice.reducer