// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getSchoolAdminScheduleMyDetail = createAsyncThunk('schooolAdmin/scheduleMy/detail', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminScheduleMyGetDetail(getState().schoolAdminScheduleMyDetailModal.params)
  return response.data
})

export const schoolAdminScheduleMyDetailModalSlice = createSlice({
  name: 'schoolAdminScheduleMyDetailModal',
  initialState: {
    isModalOpened: false,
    isLoading: false,
    data: null,
    params: {
      lessonId: null
    },
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
    closeModal(state, action) {
      state.params = { lessonId: null }
      state.isModalOpened = false
      state.isLoading = false
      state.data = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSchoolAdminScheduleMyDetail.pending, (state) => {
      state.isLoading = true
      state.isModalOpened = true
    })
    builder.addCase(getSchoolAdminScheduleMyDetail.fulfilled, (state, action) => {
      state.data = action.payload.data.lesson
      // state.total = action.payload._meta.pagination.total
      state.isLoading = false
    })
    
  }
})

export const { changeParams, closeModal } = schoolAdminScheduleMyDetailModalSlice.actions

export default schoolAdminScheduleMyDetailModalSlice.reducer