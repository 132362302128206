// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getLessonsStudent = createAsyncThunk('schoolAdmin/lessons/students/get', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminLessonsStudentsGet(payload)
  return response.data
})

// export const deleteLessonsGroup = createAsyncThunk('schoolAdmin/lessons/groups/delete', async (payload, { dispatch, getState }) => {
//   const response = await useApi.schoolAdminLessonsGroupsDelete(payload)
//   return response.data
// })

export const schoolAdminLessonsStudentsDetailSlice = createSlice({
  name: 'schoolAdminLessonsStudentsDetail',
  initialState: {
    isLoading: false,
    student: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLessonsStudent.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getLessonsStudent.fulfilled, (state, action) => {
      state.student = action.payload.data.student
      state.isLoading = false
    })
  }
})

export default schoolAdminLessonsStudentsDetailSlice.reducer