import ApiService from './apiService'

// ** Export Service as useJwt
export default function useApi(overrideConfig = {}) {
  const api = new ApiService(overrideConfig)

  return {
    api
  }
}
