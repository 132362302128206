// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getHomeworkList = createAsyncThunk('student/classes/homework/list', async (payload, { dispatch, getState }) => {
  const response = await useApi.studentClassHomeworkList(payload, getState().studentClassHomeworkModal.params)
  return response.data
})

export const studentClassHomeworkModalSlice = createSlice({
  name: 'studentClassHomeworkModal',
  initialState: {
    isLoading: true,
    data: [],
    total: 0,
    params: {
      itemsPerPage: 10,
      currentPage: 1
    },
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHomeworkList.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getHomeworkList.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload._meta.pagination.total
      state.isLoading = false
    })
    
  }
})

export const { changeParams } = studentClassHomeworkModalSlice.actions

export default studentClassHomeworkModalSlice.reducer