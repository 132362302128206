// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getLessonsStudentsDetailClasses = createAsyncThunk('schoolAdmin/lessons/students/detail/classes', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminLessonsStudentsClassesList(payload, getState().schoolAdminLessonsStudentsDetailClasses.params)
  return response.data
})

export const deleteLessonsStudentsDetailClasses = createAsyncThunk('schoolAdmin/lessons/groups/detail/classes/delete', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminLessonsStudentsClassesDelete(payload.userId, payload.id)
  await dispatch(getLessonsStudentsDetailClasses(payload.userId))
  return response.data
})

export const schoolAdminLessonsStudentsDetailClassesSlice = createSlice({
  name: 'schoolAdminLessonsStudentsDetailClasses',
  initialState: {
    isLoading: false,
    data: [],
    total: 0,
    params: {
      itemsPerPage: 10,
      currentPage: 1,
      q: ''
    },
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLessonsStudentsDetailClasses.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getLessonsStudentsDetailClasses.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload._meta.pagination.total
      state.isLoading = false
    })
    
  }
})

export const { changeParams } = schoolAdminLessonsStudentsDetailClassesSlice.actions

export default schoolAdminLessonsStudentsDetailClassesSlice.reducer