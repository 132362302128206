// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getAnalyticsUsersActivity = createAsyncThunk('teacher/analytics/usersActivity', async (payload, { dispatch, getState }) => {
  const response = await useApi.teacherAnalytics(getState().teacherAnalyticsUsersActivity.params)
  return response.data
})

export const teacherAnalyticsUsersActivitySlice = createSlice({
  name: 'teacherAnalyticsUsersActivity',
  initialState: {
    isLoading: false,
    data: null,
    params: {
      //period: 'this-week', // [this-week, previous-week, this-month, previous-month, 6-months, 1 - year],
      type: 'users-activity'
    },
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAnalyticsUsersActivity.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getAnalyticsUsersActivity.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.isLoading = false
    })
    
  }
})

export const { changeParams } = teacherAnalyticsUsersActivitySlice.actions

export default teacherAnalyticsUsersActivitySlice.reducer