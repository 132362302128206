// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getAnalyticsStudents = createAsyncThunk('admin/analytics/Students', async (payload, { dispatch, getState }) => {
  const response = await useApi.adminAnalyticsStudents(payload)
  return response.data
})

export const adminAnalyticsStudentsSlice = createSlice({
  name: 'adminAnalyticsStudents',
  initialState: {
    isLoading: false,
    data: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAnalyticsStudents.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getAnalyticsStudents.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.isLoading = false
    })
    
  }
})

//export const { changeParams } = adminAnalyticsStudentsSlice.actions

export default adminAnalyticsStudentsSlice.reducer