// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('schoolAdmin/schoolsPlans/getData', async params => {
  const response = await useApi.schoolAdminGetSchoolsPlans(params)

  return {
    params,
    data: response.data.data,
    totalPages: response.data['_meta']['pagination']['total'] || 0
  }
})

export const getUser = createAsyncThunk('schoolAdmin/schoolsPlans/getPlan', async id => {
  const response = await useApi.schoolAdminGetAdmin(id)
  return response.data.data
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  return id
})

export const archiveSchoolPlan = createAsyncThunk('schoolAdmin/schoolsPlans/archivePlan', async (id, { dispatch, getState }) => {
  const response = await useApi.schoolAdminArchiveSchoolsPlan(id)
  await dispatch(getData(getState().schoolAdminSchoolsPlans.params))
  return response.data.data
})

export const schoolsPlansSlice = createSlice({
  name: 'schoolAdminSchoolsPlans',
  initialState: {
    data: [],
    total: 0,
    params: {},
    editablePlan: null
  },
  reducers: {
    editPlan(state, action) {
      state.editablePlan = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export const { editPlan } = schoolsPlansSlice.actions

export default schoolsPlansSlice.reducer
