// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getSystemSettings = createAsyncThunk('admin/systemSettings/get', async (payload, { dispatch, getState }) => {
  const response = await useApi.adminSystemSettingsGet()
  return response.data
})

export const updateSystemSettings = createAsyncThunk('admin/systemSettings/update', async (payload, { dispatch, getState }) => {
  const response = await useApi.adminSystemSettingsUpdate(payload)

  await dispatch(getSystemSettings())

  return response.data
})

export const schoolAdminLessonsGroupsDetailSlice = createSlice({
  name: 'adminSystemSettings',
  initialState: {
    isUpdating: false,
    isLoading: true,
    settings: null
  },
  reducers: {},
  extraReducers: (builder) => {
    // Get
    builder.addCase(getSystemSettings.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getSystemSettings.fulfilled, (state, action) => {
      state.settings = action.payload.data.settings
      state.isLoading = false
    })
    // Update
    builder.addCase(updateSystemSettings.pending, (state) => {
      state.isUpdating = true
    })
    builder.addCase(updateSystemSettings.fulfilled, (state, action) => {
      state.isUpdating = false
    })
    builder.addCase(updateSystemSettings.rejected, (state, action) => {
      state.isUpdating = false
    })
  }
})

export default schoolAdminLessonsGroupsDetailSlice.reducer