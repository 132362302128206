// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getSchoolAdminLessonsStudentsDetailClassesSchedule = createAsyncThunk('schoolAdmin/lessons/groups/detail/classes/schedule', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminLessonsStudentsDetailClassesScheduleGet(
    payload.userId, payload.classId,
    getState().schoolAdminLessonsStudentsDetailClassesSchedule.params
  )
  return response.data
})

export const schoolAdminLessonsStudentsDetailClassesScheduleSlice = createSlice({
  name: 'schoolAdminLessonsStudentsDetailClassesSchedule',
  initialState: {
    isLoading: false,
    data: {},
    total: 0,
    params: {},
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSchoolAdminLessonsStudentsDetailClassesSchedule.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getSchoolAdminLessonsStudentsDetailClassesSchedule.fulfilled, (state, action) => {
      state.data[action.payload.data.classId] = action.payload.data.lessons
      // state.total = action.payload._meta.pagination.total
      state.isLoading = false
    })
    
  }
})

export const { changeParams } = schoolAdminLessonsStudentsDetailClassesScheduleSlice.actions

export default schoolAdminLessonsStudentsDetailClassesScheduleSlice.reducer