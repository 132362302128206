// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'
import toast from 'react-hot-toast'

export const getHandbook = createAsyncThunk('admin/handbook/get', async (payload, { dispatch, getState }) => {
  const response = await useApi.adminHandbookGet(payload)
  return response.data
})

export const editHandbook = createAsyncThunk('admin/handbook/edit', async (payload, { dispatch, getState }) => {
  const response = await useApi.adminHandbookEdit(payload.id, payload)

  return response.data
})

export const deleteHandbook = createAsyncThunk('admin/handbook/delete', async (payload, { dispatch, getState }) => {
  const response = await useApi.adminHandbookDelete(payload)

  return response.data
})

export const adminHandbookSlice = createSlice({
  name: 'adminHandbookEdit',
  initialState: {
    isLoading: false,
    isEditnig: false,
    data: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getHandbook.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getHandbook.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.isLoading = false
    })

    builder.addCase(editHandbook.pending, (state) => {
      state.isEditnig = true
    })
    builder.addCase(editHandbook.rejected, (state, action) => {
      toast.error('Сталась помилка. Спробуйте ще раз.', {
        duration: 5000
      })

      state.isEditnig = false
    })
    builder.addCase(editHandbook.fulfilled, (state, action) => {
      state.isEditnig = false

      if (action.payload.success) {
        setTimeout(() => {
          window.location.href = `/handbook/edit/${action.payload.data.id}`
        }, 50)
      }
    })

    builder.addCase(deleteHandbook.fulfilled, (state, action) => {
      state.isEditnig = false

      if (action.payload.success) {
        setTimeout(() => {
          window.location.href = `/handbook`
        }, 50)
      }
    })
    
  }
})

export const { changeParams } = adminHandbookSlice.actions

export default adminHandbookSlice.reducer