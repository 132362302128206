// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './auth'
import system from './system'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import kanban from '@src/views/apps/kanban/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'

import schoolAdminAdmins from '@src/views/schoolAdmin/admins/store'
import schoolAdminTeachers from '@src/views/schoolAdmin/teachers/store'
import schoolAdminStudents from '@src/views/schoolAdmin/students/store'
import schoolAdminCourses from '@src/views/schoolAdmin/courses/list/store'
import schoolAdminCoursesDetail from '@src/views/schoolAdmin/courses/detail/store'
import schoolAdminCoursesLessonDetail from '@src/views/schoolAdmin/courses/lessons/store'
import schoolAdminSchoolsPlans from '@src/views/schoolAdmin/schoolSettings/schoolsPlans/store'
import schoolAdminSchoolSettings from '@src/views/schoolAdmin/schoolSettings/store'
import schoolAdminLessonsStudents from '@src/views/schoolAdmin/lessons/students/store'
import schoolAdminLessonsTeachers from '@src/views/schoolAdmin/lessons/teachers/store'
import schoolAdminLessonsTeacherDetailSchedule from '@src/views/schoolAdmin/lessons/teachers/detail/schedule/store'
import schoolAdminLessonsGroups from '@src/views/schoolAdmin/lessons/groups/store'
import schoolAdminLessonsGroupsDetail from '@src/views/schoolAdmin/lessons/groups/detail/store'
import schoolAdminLessonsGroupsDetailStudents from '@src/views/schoolAdmin/lessons/groups/detail/students/store'


import schoolAdminLessonsTeacherDetail from '@src/views/schoolAdmin/lessons/teachers/detail/store'
import schoolAdminLessonsTeacherDetailClasses from '@src/views/schoolAdmin/lessons/teachers/detail/classes/store'
import schoolAdminLessonsStudentsDetailClassesSchedule from '@src/views/schoolAdmin/lessons/students/detail/classes/schedule/store'

import schoolAdminLessonsStudentsDetail from '@src/views/schoolAdmin/lessons/students/detail/store'
import schoolAdminLessonsStudentsDetailClasses from '@src/views/schoolAdmin/lessons/students/detail/classes/store'
import schoolAdminAnalyticsLessons from '@src/views/schoolAdmin/analytics/lessons/store'
import schoolAdminAnalyticsRevenue from '@src/views/schoolAdmin/analytics/revenue/store'
import schoolAdminAnalyticsAverageCheck from '@src/views/schoolAdmin/analytics/averageCheck/store'
import schoolAdminAnalyticsUsersActivity from '@src/views/schoolAdmin/analytics/usersActivity/store'
import schoolAdminAnalyticsUsersPayments from '@src/views/schoolAdmin/analytics/usersPayments/store'
import schoolAdminAnalyticsUsersExpired from '@src/views/schoolAdmin/analytics/usersExpired/store'
import schoolAdminAnalyticsTeachersGradation from '@src/views/schoolAdmin/analytics/teachersGradation/store'

import schoolCalendar from '@src/views/schoolAdmin/calendar/store'
import schoolAdminSchedule from '@src/views/schoolAdmin/schedule/store'
import schoolAdminScheduleDetail from '@src/views/schoolAdmin/schedule/detail/store'
import schoolAdminScheduleCreateLessonModal from '@src/views/schoolAdmin/schedule/createLessonModal/store'

import schoolAdminHandbookList from '@src/views/schoolAdmin/handbook/list/store'
import schoolAdminHandbookView from '@src/views/schoolAdmin/handbook/view/store'
import schoolAdminClasses from '@src/views/schoolAdmin/classes/list/store'
import schoolAdminClass from '@src/views/schoolAdmin/classes/detail/store'
import schoolAdminClassCourses from '@src/views/schoolAdmin/classes/detail/coursesModal/store'
import schoolAdminClassHomeworkBlock from '@src/views/schoolAdmin/classes/detail/homeworkBlock/store'
import schoolAdminClassHomeworkModal from '@src/views/schoolAdmin/classes/detail/homeworkModal/store'

import schoolAdminClassHomework from '@src/views/schoolAdmin/classes/homework/store'

import schoolAdminMarathonsList from '@src/views/schoolAdmin/marathons/list/store'
import schoolAdminMarathonsView from '@src/views/schoolAdmin/marathons/view/store'
import schoolAdminMarathonsViewMembers from '@src/views/schoolAdmin/marathons/view/UsersTab/store'
import schoolAdminMarathonsViewTeachers from '@src/views/schoolAdmin/marathons/view/TeachersTab/store'

import schoolAdminScheduleMy from '@src/views/schoolAdmin/scheduleMy/store'
import schoolAdminScheduleMyDetailModal from '@src/views/schoolAdmin/scheduleMy/detailModal/store'
import schoolAdminScheduleMyCreateLessonModal from '@src/views/schoolAdmin/scheduleMy/createLessonModal/store'
import schoolAdminLessonsGroupsDetailClassesSchedule from '@src/views/schoolAdmin/lessons/groups/detail/schedule/store'

import schoolAdminVocabulary from '@src/views/schoolAdmin/vocabulary/store'
import schoolAdminClassLearnedLessonsBlock from '@src/views/schoolAdmin/classes/detail/learnedLessonsBlock/store'
import schoolAdminClassLearnedLessonsModal from '@src/views/schoolAdmin/classes/detail/learnedLessonsModal/store'

import adminAdmins from '@src/views/admin/admins/store'
import adminAnalytics from '@src/views/admin/analytics/store'
import adminAnalyticsNewRegistrations from '@src/views/admin/analytics/newRegistrations/store'
import adminAnalyticsNewCoursesAndLessons from '@src/views/admin/analytics/newCoursesAndLessons/store'
import adminAnalyticsStudents from '@src/views/admin/analytics/students/store'
import adminAnalyticsTopSchoolsByProfit from '@src/views/admin/analytics/topSchoolsByProfit/store'


import adminTeachers from '@src/views/admin/teachers/store'
import adminStudents from '@src/views/admin/students/store'
import adminPayments from '@src/views/admin/payments/store'
import adminSchools from '@src/views/admin/schools/store'
import adminSchoolsTeachers from '@src/views/admin/schools/teachers/store'
import adminSchoolsStudents from '@src/views/admin/schools/students/store'
import adminSchoolsAdmins from '@src/views/admin/schools/admins/store'
import adminSchoolsPlans from '@src/views/admin/schools/plans/store'


import adminHandbookList from '@src/views/admin/handbook/list/store'
import adminHandbookEdit from '@src/views/admin/handbook/edit/store'
import adminSystemSettings from '@src/views/admin/system-settings/store'

import adminPlansPlatform from '@src/views/admin/plans/platform/store'
import adminPlansMarathons from '@src/views/admin/plans/marathons/store'



import studentCalendar from '@src/views/student/calendar/store'
import studentNotifications from '@src/views/student/notifications/store'
import studentPlans from '@src/views/student/plans/store'
import studentClasses from '@src/views/student/classes/list/store'
import studentSchedule from '@src/views/student/schedule/store'
import studentClass from '@src/views/student/classes/detail/store'
import studentVocabulary from '@src/views/student/vocabulary/store'
import studentClassCourses from '@src/views/student/classes/detail/coursesModal/store'
import studentClassHomeworkBlock from '@src/views/student/classes/detail/homeworkBlock/store'
import studentClassHomeworkModal from '@src/views/student/classes/detail/homeworkModal/store'
import studentClassHomework from '@src/views/student/classes/homework/store'
import studentScheduleDetailModal from '@src/views/student/schedule/detailModal/store'

import teacherCalendar from '@src/views/teacher/calendar/store'
import teacherCourses from '@src/views/teacher/courses/list/store'
import teacherCoursesDetail from '@src/views/teacher/courses/detail/store'
import teacherCoursesLessonDetail from '@src/views/teacher/courses/lessons/store'
import teacherClasses from '@src/views/teacher/classes/list/store'
import teacherSchedule from '@src/views/teacher/schedule/store'
import teacherScheduleDetailModal from '@src/views/teacher/schedule/detailModal/store'
import teacherScheduleCreateLessonModal from '@src/views/teacher/schedule/createLessonModal/store'
import teacherClass from '@src/views/teacher/classes/detail/store'
import teacherClassCourses from '@src/views/teacher/classes/detail/coursesModal/store'
import teacherAnalyticsLessons from '@src/views/teacher/analytics/lessons/store'
import teacherAnalyticsUsersActivity from '@src/views/teacher/analytics/usersActivity/store'
import teacherVocabulary from '@src/views/teacher/vocabulary/store'
import teacherClassHomeworkBlock from '@src/views/teacher/classes/detail/homeworkBlock/store'
import teacherClassHomeworkModal from '@src/views/teacher/classes/detail/homeworkModal/store'
import teacherClassHomework from '@src/views/teacher/classes/homework/store'
import teacherClassLearnedLessonsBlock from '@src/views/teacher/classes/detail/learnedLessonsBlock/store'
import teacherClassLearnedLessonsModal from '@src/views/teacher/classes/detail/learnedLessonsModal/store'
import studentClassLearnedLessonsBlock from '@src/views/student/classes/detail/learnedLessonsBlock/store'
import studentClassLearnedLessonsModal from '@src/views/student/classes/detail/learnedLessonsModal/store'

const rootReducer = {
  system,
  auth,
  todo,
  chat,
  email,
  users,
  kanban,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  schoolAdminAdmins,
  schoolAdminTeachers,
  schoolAdminStudents,
  adminSchoolsPlans,
  schoolAdminHandbookList,
  schoolAdminHandbookView,
  teacherClassHomeworkBlock,
  teacherClassLearnedLessonsBlock,
  teacherClassHomeworkModal,
  teacherClassLearnedLessonsModal,
  studentClassLearnedLessonsModal,
  studentClassLearnedLessonsBlock,
  studentScheduleDetailModal,
  teacherClassHomework,
  studentClassHomework,
  adminSchoolsTeachers,
  adminSchoolsStudents,
  adminSchoolsAdmins,
  adminAnalyticsNewRegistrations,
  adminAnalyticsNewCoursesAndLessons,
  adminAnalyticsTopSchoolsByProfit,
  adminAnalyticsStudents,
  adminAnalytics,
  adminAdmins,
  adminTeachers,
  adminStudents,
  adminPayments,
  adminSchools,
  adminPlansPlatform,
  adminPlansMarathons,
  adminHandbookList,
  adminHandbookEdit,
  adminSystemSettings,
  schoolAdminCourses,
  schoolAdminCoursesDetail,
  schoolAdminCoursesLessonDetail,
  schoolAdminMarathonsList,
  schoolAdminMarathonsView,
  schoolAdminMarathonsViewMembers,
  schoolAdminMarathonsViewTeachers,
  schoolAdminClassLearnedLessonsBlock,
  schoolAdminClassLearnedLessonsModal,
  schoolAdminSchoolsPlans,
  schoolAdminVocabulary,
  schoolCalendar,
  studentCalendar,
  studentNotifications,
  teacherCalendar,
  teacherCourses,
  teacherCoursesDetail,
  teacherCoursesLessonDetail,
  schoolAdminSchoolSettings,
  schoolAdminLessonsStudents,
  schoolAdminLessonsTeachers,
  schoolAdminLessonsGroups,
  schoolAdminLessonsGroupsDetail,
  schoolAdminLessonsGroupsDetailStudents,
  schoolAdminLessonsTeacherDetail,
  schoolAdminLessonsTeacherDetailClasses,
  schoolAdminLessonsTeacherDetailSchedule,
  schoolAdminLessonsStudentsDetail,
  schoolAdminLessonsStudentsDetailClasses,
  schoolAdminLessonsStudentsDetailClassesSchedule,
  schoolAdminLessonsGroupsDetailClassesSchedule,
  schoolAdminAnalyticsLessons,
  schoolAdminAnalyticsRevenue,
  schoolAdminAnalyticsAverageCheck,
  schoolAdminAnalyticsUsersActivity,
  schoolAdminAnalyticsUsersPayments,
  schoolAdminAnalyticsUsersExpired,
  schoolAdminAnalyticsTeachersGradation,
  schoolAdminSchedule,
  schoolAdminScheduleDetail,
  schoolAdminScheduleCreateLessonModal,
  schoolAdminClasses,
  schoolAdminClass,
  schoolAdminClassHomeworkBlock,
  schoolAdminClassHomeworkModal,
  schoolAdminClassHomework,
  schoolAdminClassCourses,
  schoolAdminScheduleMy,
  schoolAdminScheduleMyDetailModal,
  schoolAdminScheduleMyCreateLessonModal,
  studentPlans,
  studentClasses,
  studentSchedule,
  studentVocabulary,
  studentClassCourses,
  teacherClasses,
  teacherSchedule,
  teacherScheduleDetailModal,
  teacherScheduleCreateLessonModal,
  teacherClass,
  studentClass,
  studentClassHomeworkBlock,
  studentClassHomeworkModal,
  teacherClassCourses,
  teacherAnalyticsLessons,
  teacherAnalyticsUsersActivity,
  teacherVocabulary,
}

export default rootReducer
