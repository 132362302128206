// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'
import toast from 'react-hot-toast'

export const getHandbookList = createAsyncThunk('schoolAdmin/handbook/list', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminHandbookList(getState().schoolAdminHandbookList.params)
  return response.data
})

export const schoolAdminHandbookSlice = createSlice({
  name: 'schoolAdminHandbookList',
  initialState: {
    isLoading: false,
    data: null,
    total: 0,
    params: {
      itemsPerPage: 10,
      currentPage: 1,
      q: ''
    },
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHandbookList.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getHandbookList.fulfilled, (state, action) => {
      state.data = action.payload.data.course

      if (state.data && state.data.courseSections) {
        state.data.courseSections = state.data.courseSections.sort((a, b) => {
          return parseInt(a.position) - parseInt(b.position);
        })

        state.data.courseSections = state.data.courseSections.map((cs) => {
          if (cs.courseLessons) {
            cs.courseLessons = cs.courseLessons.sort((a, b) => {
              return parseInt(a.position) - parseInt(b.position);
            })
          }

          return cs
        })
      }

      //state.total = action.payload._meta.pagination.total
      state.isLoading = false
    })
  }
})

export const { changeParams } = schoolAdminHandbookSlice.actions

export default schoolAdminHandbookSlice.reducer