// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getLessonsTeacher = createAsyncThunk('schoolAdmin/lessons/teachers/get', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminLessonsTeachersGet(payload)
  return response.data
})

export const schoolAdminLessonsGroupsDetailSlice = createSlice({
  name: 'schoolAdminLessonsTeacherDetail',
  initialState: {
    isLoading: false,
    teacher: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLessonsTeacher.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getLessonsTeacher.fulfilled, (state, action) => {
      state.teacher = action.payload.data.teacher
      state.isLoading = false
    })
    
  }
})

export default schoolAdminLessonsGroupsDetailSlice.reducer