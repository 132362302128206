// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getAnalyticsLessons = createAsyncThunk('schoolAdmin/analytics/lessons', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminAnalytics(getState().schoolAdminAnalyticsLessons.params)
  return response.data
})

export const schoolAdminAnalyticsLessonsSlice = createSlice({
  name: 'schoolAdminAnalyticsLessons',
  initialState: {
    isLoading: false,
    data: null,
    params: {
      period: 'this-week', // [this-week, previous-week, this-month, previous-month, 6-months, 1 - year],
      type: 'lessons'
    },
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAnalyticsLessons.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getAnalyticsLessons.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.isLoading = false
    })
    
  }
})

export const { changeParams } = schoolAdminAnalyticsLessonsSlice.actions

export default schoolAdminAnalyticsLessonsSlice.reducer