// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getCourse = createAsyncThunk('teacher/courses/getCourse', async id => {
  const response = await useApi.teacherGetCourse(id)
  return response.data.data
})

export const appUsersSlice = createSlice({
  name: 'teacherCoursesDetail',
  initialState: {
    selectedCourse: null,
    selectedCourseId: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCourse.fulfilled, (state, action) => {
      state.selectedCourse = action.payload.course

      if (state.selectedCourse && state.selectedCourse.courseSections) {
        state.selectedCourse.courseSections = state.selectedCourse.courseSections.sort((a, b) => {
          return parseInt(a.position) - parseInt(b.position);
        })

        state.selectedCourse.courseSections = state.selectedCourse.courseSections.map((cs) => {
          if (cs.courseLessons) {
            cs.courseLessons = cs.courseLessons.sort((a, b) => {
              return parseInt(a.position) - parseInt(b.position);
            })
          }

          return cs
        })
      }
      
      state.selectedCourseId = action.payload.course.id
    })
  }
})

export default appUsersSlice.reducer
