// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getAnalyticsNewRegistrations = createAsyncThunk('admin/analytics/newRegistrations', async (payload, { dispatch, getState }) => {
  const response = await useApi.adminAnalyticsNewRegistrations(payload)
  return response.data
})

export const adminAnalyticsNewRegistrationsSlice = createSlice({
  name: 'adminAnalyticsNewRegistrations',
  initialState: {
    isLoading: false,
    data: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAnalyticsNewRegistrations.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getAnalyticsNewRegistrations.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.isLoading = false
    })
    
  }
})

//export const { changeParams } = adminAnalyticsNewRegistrationsSlice.actions

export default adminAnalyticsNewRegistrationsSlice.reducer