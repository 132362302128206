// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getTopSchoolsByProfit = createAsyncThunk('admin/analytics/topSchoolsByProfit', async (payload, { dispatch, getState }) => {
  const response = await useApi.adminAnalyticsTopSchoolsByProfit(payload)
  return response.data
})

export const adminAnalyticsTopSchoolsByProfitSlice = createSlice({
  name: 'adminAnalyticsTopSchoolsByProfit',
  initialState: {
    isLoading: false,
    data: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTopSchoolsByProfit.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getTopSchoolsByProfit.fulfilled, (state, action) => {
      state.data = action.payload.data.schools
      state.isLoading = false
    })
  }
})

// export const { changeParams } = adminAnalyticsTopSchoolsByProfitSlice.actions

export default adminAnalyticsTopSchoolsByProfitSlice.reducer