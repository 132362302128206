// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getVocabulary = createAsyncThunk('teacher/vocabulary', async (payload, { dispatch, getState }) => {
  const response = await useApi.teacherVocabularyList(getState().teacherVocabulary.params)
  return response.data
})

// export const createVocabulary = createAsyncThunk('teacher/vocabulary/create', async (payload, { dispatch, getState }) => {
//   const response = await useApi.teacherVocabularyCreate(payload)

//   await dispatch(getVocabulary())

//   return response.data
// })

export const deleteVocabulary = createAsyncThunk('teacher/vocabulary/delete', async (payload, { dispatch, getState }) => {
  const response = await useApi.teacherVocabularyDelete(payload)

  await dispatch(getVocabulary())

  return response.data
})

export const teacherVocabularySlice = createSlice({
  name: 'teacherVocabulary',
  initialState: {
    isLoading: false,
    isProcessing: false,
    isCreateModalOpened: false,
    data: [],
    total: 0,
    params: {
      itemsPerPage: 10,
      currentPage: 1,
      q: '',
      userId: null
    },
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
    toggleCreateModal(state, action) {
      state.isCreateModalOpened = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVocabulary.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getVocabulary.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload._meta.pagination.total
      state.isLoading = false
    })

    // builder.addCase(createVocabulary.pending, (state) => {
    //   state.isProcessing = true
    // })
    // builder.addCase(createVocabulary.fulfilled, (state, action) => {
    //   state.isProcessing = false
    // })
    
    // builder.addCase(deleteVocabulary.pending, (state) => {
    //   state.isProcessing = true
    // })
    // builder.addCase(deleteVocabulary.fulfilled, (state, action) => {
    //   state.isProcessing = false
    // })
  }
})

export const { changeParams, toggleCreateModal } = teacherVocabularySlice.actions

export default teacherVocabularySlice.reducer