// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getLessonsGroupsDetailStudents = createAsyncThunk('schoolAdmin/lessons/groups/detail/students', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminLessonsGroupsStudentsList(payload, getState().schoolAdminLessonsGroupsDetailStudents.params)
  return response.data
})

export const lessonsGroupsDetailDetachStudents = createAsyncThunk('schoolAdmin/lessons/groups/detail/students/detach', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminLessonsGroupsStudentsDetach(payload.id, payload.userId)
  await dispatch(getLessonsGroupsDetailStudents(payload.id))
  return response.data
})

export const schoolAdminLessonsGroupsDetailStudentsSlice = createSlice({
  name: 'schoolAdminLessonsGroupsDetailStudents',
  initialState: {
    isLoading: false,
    data: [],
    total: 0,
    params: {
      itemsPerPage: 10,
      currentPage: 1,
      q: ''
    },
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLessonsGroupsDetailStudents.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getLessonsGroupsDetailStudents.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload._meta.pagination.total
      state.isLoading = false
    })
    
  }
})

export const { changeParams } = schoolAdminLessonsGroupsDetailStudentsSlice.actions

export default schoolAdminLessonsGroupsDetailStudentsSlice.reducer