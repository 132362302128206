// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const schooladminScheduleMyCreateLessonModalSlice = createSlice({
  name: 'schooladminScheduleMyCreateLessonModal',
  initialState: {
    isModalOpened: false,
    isLoading: false,
    data: null,
    selectedDate: null,
    params: {
     
    },
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
    closeModal(state, action) {
      state.params = { lessonId: null }
      state.isModalOpened = false
      state.isLoading = false
      state.data = null
    },
    openModal(state, action) {
      state.isModalOpened = true
      state.selectedDate = action.payload
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(getschooladminScheduleMyDetail.pending, (state) => {
    //   state.isLoading = true
    //   state.isModalOpened = true
    // })
    // builder.addCase(getschooladminScheduleMyDetail.fulfilled, (state, action) => {
    //   state.data = action.payload.data.lesson
    //   // state.total = action.payload._meta.pagination.total
    //   state.isLoading = false
    // })
    
  }
})

export const { changeParams, closeModal, openModal } = schooladminScheduleMyCreateLessonModalSlice.actions

export default schooladminScheduleMyCreateLessonModalSlice.reducer