// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'
import toast from 'react-hot-toast'

export const getMarathonsMembers = createAsyncThunk('schoolAdmin/marathons/members/list', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminMarathonsMembers(payload, getState().schoolAdminMarathonsViewMembers.params)
  return response.data
})

export const deleteMarathonsMember = createAsyncThunk('schoolAdmin/marathons/members/delete', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminMarathonsMembersDelete(
    payload.marathonId, payload.memberId, {}
  )

  await dispatch(getMarathonsMembers(payload.marathonId))
  return response.data
})

export const makePaymentMarathonsMember = createAsyncThunk('schoolAdmin/marathons/members/makePayment', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminMarathonsMembersMakePayment(
    payload.marathonId, payload.memberId, {}
  )

  await dispatch(getMarathonsMembers(payload.marathonId))
  return response.data
})


export const schoolAdminMarathonsViewMembersSlice = createSlice({
  name: 'schoolAdminMarathonsViewMembers',
  initialState: {
    isLoading: false,
    data: [],
    params: {
      itemsPerPage: 10,
      currentPage: 1,
      q: ''
    }
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMarathonsMembers.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getMarathonsMembers.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload._meta.pagination.total
      state.isLoading = false
    })
    builder.addCase(deleteMarathonsMember.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(deleteMarathonsMember.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(makePaymentMarathonsMember.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(makePaymentMarathonsMember.fulfilled, (state) => {
      state.isLoading = false
    })
  }
})

export const { changeParams } = schoolAdminMarathonsViewMembersSlice.actions

export default schoolAdminMarathonsViewMembersSlice.reducer