// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'
import toast from 'react-hot-toast'

export const getSystemInfo = createAsyncThunk('system/info', async (payload, { dispatch, getState }) => {
  const response = await useApi.getSystemInfo()
  return response.data
})

export const systemSlice = createSlice({
  name: 'system',
  initialState: {
    isLoading: true,
    data: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSystemInfo.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getSystemInfo.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.isLoading = false
    })
    builder.addCase(getSystemInfo.rejected, (state, action) => {
      toast.error('Щось пішло не так. Обновіть будь ласка сторінку', {
        duration: 5000
      })
    })
  }
})

export const { changeParams } = systemSlice.actions

export default systemSlice.reducer