// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getLessonsGroup = createAsyncThunk('schoolAdmin/lessons/groups/get', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminLessonsGroupsGet(payload)
  return response.data
})

export const deleteLessonsGroup = createAsyncThunk('schoolAdmin/lessons/groups/delete', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminLessonsGroupsDelete(payload)
  return response.data
})

export const schoolAdminLessonsGroupsDetailSlice = createSlice({
  name: 'schoolAdminLessonsGroupsDetail',
  initialState: {
    isLoading: false,
    group: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLessonsGroup.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getLessonsGroup.fulfilled, (state, action) => {
      state.group = action.payload.data.group
      state.isLoading = false
    })
    builder.addCase(deleteLessonsGroup.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(deleteLessonsGroup.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.group = null
      }
      state.isLoading = false

      setTimeout(() => {
        window.location.href = '/lessons/groups'
      }, 50)
    })
  }
})

export default schoolAdminLessonsGroupsDetailSlice.reducer