// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getAnalyticsNewCoursesAndLessons = createAsyncThunk('admin/analytics/newCoursesAndLessons', async (payload, { dispatch, getState }) => {
  const response = await useApi.adminAnalyticsNewCoursesAndLessons(payload)
  return response.data
})

export const adminAnalyticsNewCoursesAndLessonsSlice = createSlice({
  name: 'adminAnalyticsNewCoursesAndLessons',
  initialState: {
    isLoading: false,
    data: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAnalyticsNewCoursesAndLessons.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getAnalyticsNewCoursesAndLessons.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.isLoading = false
    })
    
  }
})

//export const { changeParams } = adminAnalyticsNewCoursesAndLessonsSlice.actions

export default adminAnalyticsNewCoursesAndLessonsSlice.reducer