// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getTeacherSchedule = createAsyncThunk('teacher/schedule', async (payload, { dispatch, getState }) => {
  const response = await useApi.teacherScheduleGet(getState().teacherSchedule.params)
  return response.data
})

export const teacherScheduleSlice = createSlice({
  name: 'teacherSchedule',
  initialState: {
    isLoading: false,
    expiredLessonsExists: false,
    data: [],
    total: 0,
    params: {},
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTeacherSchedule.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getTeacherSchedule.fulfilled, (state, action) => {
      state.data = action.payload.data.lessons

      if (action.payload.data.expiredLessonsCount && action.payload.data.expiredLessonsCount > 0) {
        state.expiredLessonsExists = true
      } else {
        state.expiredLessonsExists = false
      }
      // state.total = action.payload._meta.pagination.total
      state.isLoading = false
    })
    
  }
})

export const { changeParams } = teacherScheduleSlice.actions

export default teacherScheduleSlice.reducer