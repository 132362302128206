// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'
import moment from 'moment'

export const getSchools = createAsyncThunk('admin/analytics/getSchools', async (payload, { dispatch, getState }) => {
  const response = await useApi.adminGetSchools({
    page: 1,
    perPage: 1000000
  })
  
  return response.data
})

export const adminAnalyticsAverageCheckSlice = createSlice({
  name: 'adminAnalytics',
  initialState: {
    isSchoolsLoading: true,
    data: {
      schools: []
    },
    params: {
      startDate: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      schoolId: null
    },
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
    setSchoolsLoading(state, action) {
      state.isSchoolsLoading = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSchools.pending, (state) => {
      state.isSchoolsLoading = true
    })
    builder.addCase(getSchools.fulfilled, (state, action) => {
      state.data.schools = action.payload.data
      state.isSchoolsLoading = false
    })
    builder.addCase(getSchools.rejected, (state) => {
      state.isSchoolsLoading = false
    })
  }
})

export const { changeParams } = adminAnalyticsAverageCheckSlice.actions

export default adminAnalyticsAverageCheckSlice.reducer